<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลธนาคาร
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ธนาคาร</label>
                <v-select v-model="result.bank_code" ref="bank_code" :options="option_bank" label="text" placeholder="เลือกธนาคาร" return-object />
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ชื่อบัญชี</label>
                <input type="text" v-model="result.account_name" ref="account_name" class="form-control"  placeholder="ข้อมูลชื่อบัญชี">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">เลขบัญชี</label>
                <input type="text" v-model="result.bank_number" ref="bank_number" class="form-control"  placeholder="ข้อมูลเลขบัญชี" maxlength="15">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Username</label>
                <input type="text" v-model="result.username" ref="username" class="form-control"  placeholder="Username">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Password</label>
                <input type="text" v-model="result.password" ref="password" class="form-control"  placeholder="Password">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">เวลาปิดระบบฝากออโต้ (จาก) รุปแบบตัวอย่าง 00:15</label>
                <input type="text" ref="start_time_can_not_deposit" v-bind="{ disabled: result.status_withdraw == 1 }" v-model="result.start_time_can_not_deposit" class="form-control"  placeholder="เวลาปิดระบบฝากออโต้ (จาก)">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">เวลาปิดระบบฝากออโต้ (ถึง) รุปแบบตัวอย่าง 00:30</label>
                <input type="text" ref="end_time_can_not_deposit" v-bind="{ disabled: result.status_withdraw == 1 }" v-model="result.end_time_can_not_deposit" class="form-control"  placeholder="เวลาปิดระบบฝากออโต้ (ถึง)">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">สถานะ</label>
                <b-form-select ref="status" v-model="result.status" :options="option" />
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">บัญชีที่ใช้ถอน</label>
                <b-form-select ref="status_withdraw" v-model="result.status_withdraw" :options="option" />
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">จำนวนเงินถอนออโต้ได้ไม่เกิน (บาท/ครั้ง)</label>
                <input type="number" ref="max_amount_withdraw_auto" v-bind="{ disabled: result.status_withdraw == 0 }" v-model="result.max_amount_withdraw_auto" class="form-control" placeholder="จำนวนเงินถอนออโต้ได้ไม่เกิน (บาท/ครั้ง)">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-8">
              <b-form-group>
                <label class="control-label">ข้อความแสดงหน้าฝากเงิน (เวลาปิดระบบฝากออโต้)</label>
                <textarea type="text" ref="message_can_not_deposit" v-model="result.message_can_not_deposit" class="form-control" placeholder="ข้อความแสดงหน้าฝากเงิน (เวลาปิดระบบฝากออโต้)" cols="3"></textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <h3 class="card-title mt-2">API</h3>
          <hr />
          <b-row class="mt-3">
            <b-col cols="md-6">
              <b-form-group>
                <label class="control-label">Device ID</label>
                <textarea type="text" ref="api_token_1" v-model="result.api_token_1" class="form-control" placeholder="Device ID" rows="3"></textarea>
              </b-form-group>
            </b-col>
            <b-col cols="md-6">
              <b-form-group>
                <label class="control-label">PIN</label>
                <textarea type="text" ref="api_token_2" v-model="result.api_token_2" class="form-control" placeholder="PIN" rows="3"></textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="PATH" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1 mr-1" /></span>ย้อนกลับ</router-link>
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <!-- สิ้นสุดท้าย -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      PATH: '/bank',
      BASE_URL: process.env.BASE_URL,
      API_GET_URL: 'bank/bank_update/' + this.$route.params.id,
      API_UPDATE_URL: 'bank/bank_update/' + this.$route.params.id,
      result: {
        bank_code: '',
        account_name: '',
        bank_number: '',
        username: '',
        password: '',
        start_time_can_not_deposit: '',
        end_time_can_not_deposit: '',
        status: 1,
        status_withdraw: 1,
        max_amount_withdraw_auto: '',
        message_can_not_deposit: '',
        api_token_1: '',
        api_token_2: '',
      },
      option_bank: [
        { text: 'ธนาคารกรุงเทพ จำกัด (มหาชน)', value: '01' },
        { text: 'ธนาคารกสิกรไทย จำกัด (มหาชน)', value: '02' },
        { text: 'ธนาคารกรุงไทย จำกัด (มหาชน)', value: '03' },
        { text: 'ธนาคารทหารไทยธนชาต จำกัด (มหาชน)', value: '04' },
        { text: 'ธนาคารไทยพาณิชย์ จำกัด (มหาชน)', value: '05' },
        { text: 'ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)', value: '06' },
        { text: 'ธนาคารออมสิน จำกัด (มหาชน)', value: '07' },
        { text: 'ธนาคารธนชาติ จำกัด (มหาชน)', value: '08' },
        { text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร จำกัด (มหาชน)', value: '09' },
      ],
      option: [
        { text: 'ปิด', value: '0' },
        { text: 'เปิด', value: '1' },
      ],
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    success() {
      this.$swal({
        title: 'แจ้งเตือน',
        text: 'แก้ไขสำเร็จแล้ว!',
        icon: 'success',
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    fetch() {
      this.$http.get(this.API_GET_URL).then(res => {
        if (res.data != null && res.data.result != null) {
          this.result = res.data.result
          this.result.bank_code = this.option_bank.find(b=>b.value==this.result.bank_code)
        }
        else this.$router.push(this.PATH)
      }).catch(()=>this.$router.push(this.PATH))
    },
    update() {
      if (this.result.bank_code == '') {
        this.$refs.bank_code.$el.focus()
        return this.warn('กรุณาเลือกธนาคาร')
      }
      if (this.result.account_name.trim().length == '') {
        this.$refs.account_name.focus()
        return this.warn('กรุณาระบุชื่อบัญชี')
      }
      if (this.result.bank_number.trim().length == 0) {
        this.$refs.bank_number.focus()
        return this.warn('กรุณาระบุเลขบัญชี')
      }
      if (this.result.bank_number.trim().length < 10) {
        this.$refs.bank_number.focus()
        return this.warn('เลขบัญชีไม่ถูกต้อง')
      }
      if (this.result.username.trim().length == 0) {
        this.$refs.username.focus()
        return this.warn('กรุณาระบุ Username')
      }
      if (this.result.password.trim().length == 0) {
        this.$refs.password.focus()
        return this.warn('กรุณาระบุ Password')
      }
      this.result.status = Number(this.result.status)
      this.result.status_withdraw = Number(this.result.status_withdraw)
      if (this.result.status_withdraw == 0 && this.result.start_time_can_not_deposit == "") {
        this.$refs.start_time_can_not_deposit.focus()
        return this.warn('กรุณาระบุเวลาจาก-ถึง ที่ปิดระบบฝากออโต้')
      }
      if (this.result.status_withdraw == 0 && this.result.end_time_can_not_deposit == "") {
        this.$refs.end_time_can_not_deposit.focus()
        return this.warn('กรุณาระบุเวลาจาก-ถึง ที่ปิดระบบฝากออโต้')
      }
      if (this.result.status_withdraw == 1 && this.result.max_amount_withdraw_auto.trim().length == 0) {
        this.$refs.max_amount_withdraw_auto.focus()
        return this.warn('กรุณาระบุ จำนวนเงินถอนออโต้ได้ไม่เกิน (บาท/ครั้ง)')
      }
      if (this.result.api_token_1.trim().length == 0) {
        this.$refs.api_token_1.focus()
        return this.warn('กรุณาระบุ')
      }
      if (this.result.api_token_2.trim().length == 0) {
        this.$refs.api_token_2.focus()
        return this.warn('กรุณาระบุ')
      }
      this.loading()
      if (this.result.bank_code.value != null) this.result.bank_code = this.result.bank_code.value
      this.$http.post(this.API_UPDATE_URL, this.result).then(res=> {
        this.$swal.close()
        this.success()
      }).catch(()=>this.$router.push(this.PATH))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
